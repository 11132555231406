import * as $ from "jquery";
import "bootstrap-sass/assets/javascripts/bootstrap/carousel";

export let carouselSwipe = {
    initialize: function () {
        // Carousel swipe on mobile
        $('.carousel').on('touchstart', function (event) {
            const xClick = event.originalEvent?.touches[0].pageX;
            $(this).one('touchmove', function (event) {
                const xMove = event.originalEvent?.touches[0].pageX;
                const sensitivityInPx = 5;
                if (xClick && xMove) {
                    if (Math.floor(xClick - xMove) > sensitivityInPx) {
                        // @ts-ignore
                        $(this).carousel('next');
                    } else if (Math.floor(xClick - xMove) < -sensitivityInPx) {
                        // @ts-ignore
                        $(this).carousel('prev');
                    }
                }
            });
            $(this).on('touchend', function () {
                $(this).off('touchmove');
            });
        });
    }
};
