import $ from "jquery";
import "bootstrap-sass/assets/javascripts/bootstrap/carousel";
import "bootstrap-sass/assets/javascripts/bootstrap/transition";
import {carouselSwipe} from "../components/carousel_swipe";

window.main = {
    initialize: function () {
        carouselSwipe.initialize();
        // Mobile-specific adjustments
        if ($('#front-page-boxes').is(':hidden')) {
            $('#upper-main-container').css('background-image', "url('{% static 'img/mob_bg.jpg' %}')");
        }
    }
};
